import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Styles from './Styles.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonType } from '@/components/form-controls/button';
import { EventsFiltering } from '@/events/components/events-filtering/use-events-filtering-operations';
import { FilterCategory } from '@/events/components/events-filtering/components/filter-category';
import { TranslationService } from '@/services/translation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventFilters, ViewMode } from '@/events/reducer/types';
import { Dropdown } from '@/components/form-controls/dropdown';
import { ZipcodeBox } from '@/events/components/events-filtering/components/zipcode-box';
import { eventsActions } from '@/events/reducer';
import { AuthenticatedUserModel } from '@/authentication/models';

type Props = {
    eventsFilteringOperations: EventsFiltering;
    onClose: () => void;
    isOpen: boolean;
    numberOfResults: number;
    hideStateFilter?: boolean;
    hideZipcodeInput?: boolean;
    hideEventTypeFilter?: boolean;
    isLargeScreen: boolean;
};

export const FiltersModal: FunctionComponent<Props> = ({
    eventsFilteringOperations,
    onClose,
    isOpen,
    numberOfResults,
    hideStateFilter = false,
    hideZipcodeInput = false,
    hideEventTypeFilter = false,
    isLargeScreen,
}) => {
    const dispatch = useDispatch();

    const modalBodyRef = useRef(null);
    const languageDivRef = useRef(null);

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const hasZipcodeBeingInput = useSelector<RootState, boolean>(
        (state) => state.events.hasZipcodeBeingInput
    );

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const resultsLabel = useMemo(() => {
        if (numberOfResults === 1) {
            return `1 ${TranslationService.getInstance().getPhrase(
                'filters.modal.results.singular'
            )}`;
        }

        return `${numberOfResults} ${TranslationService.getInstance().getPhrase(
            'filters.modal.results.plural'
        )}`;
    }, [numberOfResults]);

    const submitHandler = useCallback(() => {
        if (filters.zipcode && filters.zipcode.length === 5) {
            dispatch(eventsActions.updateViewMode(ViewMode.MAP));
        }
        onClose();
    }, [onClose, filters.zipcode, dispatch]);

    const clearHandler = useCallback(() => {
        eventsFilteringOperations.handlers.resetClickHandler();
    }, [eventsFilteringOperations.handlers]);

    const onLanguageMenuOpened = useCallback(() => {
        setTimeout(() => {
            // @ts-ignore
            modalBodyRef.current.scrollTo({
                // @ts-ignore
                top: languageDivRef.current.offsetTop,
                behavior: 'smooth',
            });
        }, 50);
    }, [modalBodyRef, languageDivRef]);

    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            dialogClassName={`${Styles.FiltersModal} ${!isLargeScreen && Styles.FiltersSmallModal}`}
            contentClassName={`${Styles.FiltersModalContent} ${
                isLargeScreen ? Styles.FiltersModalLargeContent : Styles.FiltersModalSmallContent
            }`}
            centered={isLargeScreen}
        >
            <Modal.Header className={`px-2`}>
                <Row className={`mx-0 w-100`}>
                    <Col className={`d-flex align-items-center justify-content-between`}>
                        <span className={`${Styles.Title}`}>
                            {TranslationService.getInstance().getPhrase('filters.modal.title')}
                        </span>
                        <span className={`${Styles.Results}`}>{resultsLabel}</span>
                    </Col>
                </Row>
            </Modal.Header>

            <Modal.Body
                id="modal-body-whole"
                ref={modalBodyRef}
                className={Styles.FiltersModalBody}
            >
                {!hideStateFilter && (
                    <>
                        <Dropdown
                            label={TranslationService.getInstance().getPhrase(
                                'filters.bar.state.label'
                            )}
                            preselectedOptions={filters.states}
                            options={eventsFilteringOperations.options.states}
                            onChange={eventsFilteringOperations.handlers.statesChangeHandler}
                            firstOptionRepresentsAllOptions={true}
                            uiOptions={{ top: 83 }}
                        />

                        <div className={'pb-4'} />
                    </>
                )}

                {isOpen && !hideZipcodeInput && (
                    <>
                        <ZipcodeBox
                            label={TranslationService.getInstance().getPhrase(
                                'filters.bar.zipcode.label'
                            )}
                            placeholder={TranslationService.getInstance().getPhrase(
                                'filters.bar.zipcode.placeholder'
                            )}
                            type="text"
                            value={filters.zipcode}
                            onChange={eventsFilteringOperations.handlers.zipcodeChangeHandler}
                            isValid={true}
                            showTooltipInitially={true}
                        />

                        <div className={'pb-3'} />
                    </>
                )}

                {!hideEventTypeFilter && (
                    <>
                        <FilterCategory
                            options={eventsFilteringOperations.options.eventTypes}
                            optionsSelected={filters.venueTypes}
                            setOptionsSelected={
                                eventsFilteringOperations.handlers.eventTypesChangeHandler
                            }
                            title={TranslationService.getInstance().getPhrase(
                                'filters.bar.event_type.label'
                            )}
                            disabled={!hasZipcodeBeingInput}
                        />

                        <div className={'pb-3'} />
                    </>
                )}

                <FilterCategory
                    options={eventsFilteringOperations.options.clients}
                    optionsSelected={[filters.client || '']}
                    setOptionsSelected={(newOptions) =>
                        eventsFilteringOperations.handlers.clientChangeHandler(newOptions[0])
                    }
                    title={TranslationService.getInstance().getPhrase('filters.bar.client.label')}
                    isSingleSelection
                    disabled={!!authenticatedUser || !hasZipcodeBeingInput}
                />

                <div id="language-div" ref={languageDivRef} className={'pb-3'} />

                <Dropdown
                    label={TranslationService.getInstance().getPhrase('filters.bar.language.label')}
                    preselectedOptions={filters.languages}
                    options={eventsFilteringOperations.options.languages}
                    onChange={eventsFilteringOperations.handlers.languagesChangeHandler}
                    firstOptionRepresentsAllOptions={true}
                    uiOptions={{ top: 437, position: 'unset' }}
                    onMenuOpened={onLanguageMenuOpened}
                />
            </Modal.Body>

            <Modal.Footer>
                <Row className={`${Styles.Actions} mx-0 w-100`}>
                    <Col className={`d-flex align-items-center justify-content-between pb-3`}>
                        <p
                            className={`${Styles.ClearButton} py-2 m-0 text-center`}
                            onClick={clearHandler}
                        >
                            {TranslationService.getInstance().getPhrase(
                                'filters.modal.buttons.clear_all'
                            )}
                        </p>

                        <Button
                            type={ButtonType.PRIMARY_BLUE_1}
                            onClick={submitHandler}
                            customClassName={`px-4 py-2 ${Styles.SubmitButton} h-100`}
                        >
                            {TranslationService.getInstance().getPhrase(
                                'filters.modal.buttons.done'
                            )}
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
